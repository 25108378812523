import { useLocation } from 'react-router-dom';

import { useErrorCtx } from '@/utils/ctxs';

interface Routing {
    issuerBusinessId: string;
    issuerContactId: string;
    billId: string;
    inviteCodeText: string | undefined;
    recipientBusinessId: string | undefined;
    recipientContactId: string | undefined;
}

export const useRouting = () => {
    // HOOKS
    const { pathname } = useLocation();
    const { setIs404 } = useErrorCtx();

    const routing: Routing | undefined = (() => {
        const matchArr = pathname.match(/^\/([^/?#]+)\/?([^/?#]+)\/?([^/?#]+)\/?([^/?#]+)?\/?([^/?#]+)?/);
        if (matchArr) {
            const [
                _,
                issuerBusinessId,
                issuerContactId,
                billId,
                inviteCodeTextOrRecipientBusinessId,
                recipientContactId,
            ]: (string | undefined)[] = matchArr;
            if (
                issuerBusinessId &&
                issuerContactId &&
                billId &&
                inviteCodeTextOrRecipientBusinessId &&
                recipientContactId
            ) {
                setIs404(false);
                return {
                    issuerBusinessId,
                    issuerContactId,
                    billId,
                    inviteCodeText: undefined,
                    recipientBusinessId: inviteCodeTextOrRecipientBusinessId,
                    recipientContactId,
                };
            }
            if (issuerBusinessId && issuerContactId && billId && inviteCodeTextOrRecipientBusinessId) {
                setIs404(false);
                return {
                    issuerBusinessId,
                    issuerContactId,
                    billId,
                    inviteCodeText: inviteCodeTextOrRecipientBusinessId,
                    recipientBusinessId: undefined,
                    recipientContactId: undefined,
                };
            }
        }
        setIs404(true);
        return undefined;
    })();
    console.log('routing in use->', routing);
    return routing;
};
