import { FC } from 'react';

import { useUserCtx, useErrorCtx } from '@/utils/ctxs';
import ErrorDisplay from '@/components/2_org/ErrorDisplay';
import FetchingText from '@/components/0_atom/FetchingText';

import SvgUodkaLogo from '@/svgs/uodkalogo';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateY(-50%) translateX(-50%)',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        height: 20,
    },
    content: {
        marginTop: 24,
    },
}));

const FetchingOrErrorTemplate: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { is404, is500 } = useErrorCtx();
    const { isFetching } = useUserCtx();

    return (
        <div className={c.root}>
            <SvgUodkaLogo className={c.logo} />
            <div className={c.content}>
                {isFetching ? (
                    <FetchingText />
                ) : is500 ? (
                    <ErrorDisplay errorCode={500} />
                ) : is404 ? (
                    <ErrorDisplay errorCode={404} />
                ) : (
                    <ErrorDisplay errorCode={500} />
                )}
            </div>
        </div>
    );
};
FetchingOrErrorTemplate.displayName = 'FetchingOrErrorTemplate';
export default FetchingOrErrorTemplate;
