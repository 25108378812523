import { FC } from 'react';

import { InvoiceDataItem } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';
import { getCurrencyText } from '@/utils/utilFunctions';

import { getTaxName } from '@/components/2_org/BillViewer/getTaxName';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const billThemeColor = '#397DA1';
const billThemeColorLight = '#C4D8E3';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& table': {
            borderCollapse: 'collapse',
            '& thead': {
                background: billThemeColor,
                color: '#fff',
                '& th': {
                    padding: '6px 12px',
                    whiteSpace: 'nowrap',
                    fontSize: theme.typography.caption.fontSize,
                    fontWeight: 600,
                    '&:not(:last-child)': {
                        borderRight: 'solid 1px rgba(255,255,255,.3)',
                    },
                    '&:last-child': {
                        borderRight: `solid 1px ${billThemeColor}`,
                    },
                },
            },
            '& tbody': {
                '& tr': {
                    background: billThemeColorLight,
                    borderRight: `solid 1px ${billThemeColorLight}`,
                    borderBottom: `solid 1px ${billThemeColorLight}`,
                    '& td': {
                        borderLeft: `solid 1px ${billThemeColorLight}`,
                        background: '#fff',
                        padding: '6px 12px',
                    },
                },
            },
        },
    },
    alignRight: {
        textAlign: 'right',
        whiteSpace: 'nowrap',
    },
    alignLeft: {
        textAlign: 'left',
    },
    captions: {
        textAlign: 'right',
    },
    caption: {
        fontSize: theme.typography.caption.fontSize,
        color: theme.palette.text.disabled,
    },
    tax: {
        whiteSpace: 'nowrap',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        lineHeight: 1,
        '&:not(:last-child)': {
            marginBottom: 5,
        },
    },
    taxName: {
        fontSize: theme.typography.caption.fontSize,
        paddingRight: 7,
    },
    taxValue: {},
}));

export const Items: FC<{
    items: InvoiceDataItem[];
}> = ({ items }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    // DATA
    // const isConsumptionTaxEnabled = !!items.find((item) => item.consumptionTaxRate);
    // const isWithholdingTaxEnabled = !!items.find((item) => item.withholdingTaxAmount);
    const isHasTax = !!items.find((item) => item.taxes.length > 0);

    // later: item.dateに対応
    return (
        <div className={c.root}>
            <div className={c.captions}>
                {/* {isConsumptionTaxEnabled && (
                    <div className={c.caption}>{txt({ en: 'CTR: Consumption Tax Rate', ja: '税率: 消費税率' })}</div>
                )} */}
            </div>
            <table className={c.root}>
                <colgroup>
                    <col width={'100%'} />
                </colgroup>
                <thead>
                    <tr>
                        <th>{txt({ en: 'Description', ja: '摘要' })}</th>
                        <th>{txt({ en: 'Qty', ja: '数量' })}</th>
                        <th>{txt({ en: 'Unit Price', ja: '単価' })}</th>
                        {isHasTax && <th>{txt({ en: 'Tax', ja: '税' })}</th>}
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, i) => (
                        <tr key={i}>
                            <td className={c.alignLeft}>{item.title}</td>
                            <td className={c.alignRight}>{item.quantity.toLocaleString()}</td>
                            <td className={c.alignRight}>{`¥ ${item.unitPrice.toLocaleString()}`}</td>
                            {isHasTax && (
                                <td className={c.alignLeft}>
                                    {item.taxes.length > 0
                                        ? item.taxes.map(({ title, value }, i) => (
                                              <span className={c.tax} key={i}>
                                                  <span className={c.taxName}>
                                                      {getTaxName({ taxTitle: title, txt })}
                                                  </span>
                                                  <span className={c.taxValue}>{getCurrencyText(value)}</span>
                                              </span>
                                          ))
                                        : '-'}
                                </td>
                            )}
                            {/* {isConsumptionTaxEnabled && (
                                <td className={c.alignRight}>
                                    {item.consumptionTaxRate === 0.1
                                        ? '10%'
                                        : item.consumptionTaxRate === 0.08
                                        ? '8%'
                                        : item.consumptionTaxRate === 0
                                        ? txt({ en: 'Exempt', ja: '非課税' })
                                        : '-'}
                                </td>
                            )}
                            {isWithholdingTaxEnabled && (
                                <td className={c.alignRight}>
                                    {item.withholdingTaxAmount
                                        ? `¥ ${item.withholdingTaxAmount?.toLocaleString()}`
                                        : '-'}
                                </td>
                            )} */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
Items.displayName = 'Items';
