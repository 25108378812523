import { FC } from 'react';

import { SharedBill } from '@/utils/UodkaClients/Invoices';
import Button from '@/components/0_atom/Button';

import SvgPdf from '@/svgs/pdf';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        fontWeight: 600,
        background: theme.palette.primary.main,
        color: '#fff',
        minWidth: 84,
        justifyContent: 'flex-start',
        marginBottom: 7,
        '&:hover': {
            background: 'rgba(0,0,0,.55)',
        },
    },
}));

export const DownloadPDFButton: FC<{
    bill: SharedBill;
}> = ({ bill }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <Button
            className={c.root}
            startIcon={<SvgPdf />}
            onClick={() => {
                // alert('later');
            }}
        >
            {'PDF'}
        </Button>
    );
};
DownloadPDFButton.displayName = 'DownloadPDFButton';
