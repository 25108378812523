import { FC } from 'react';

import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import { BillData } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';
import ContactNameWithIcon from '@/components/2_org/ContactNameWithIcon';

import { BillDataViewer } from '@/components/2_org/BillViewer/BillViewerDigital/BillDataViewer/BillDataViewer';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import MuiPaper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 24,
    },
    block1: {},
    block2: {
        marginTop: 16,
    },
    block3: {
        marginTop: 25,
    },
    label: {
        display: 'block',
        fontSize: theme.typography.body2.fontSize,
        marginBottom: 5,
        fontWeight: 600,
        color: theme.palette.text.disabled,
    },
}));

export const BillViewerDigital: FC<{
    issuer: {
        name: string;
        profile: BusinessProfile | undefined;
    };
    recipient: {
        name: string;
        profile: BusinessProfile | undefined;
    };
    billData: BillData;
}> = ({ issuer, recipient, billData }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    return (
        <MuiPaper className={c.root} elevation={8}>
            <div className={c.block1}>
                <MuiTypography className={c.label}>{txt({ en: 'Issuer', ja: '発行者' })}</MuiTypography>
                <ContactNameWithIcon contact={issuer} profile={issuer.profile} />
            </div>
            <div className={c.block2}>
                <MuiTypography className={c.label}>{txt({ en: 'Bill To', ja: '請求先' })}</MuiTypography>
                <ContactNameWithIcon contact={recipient} profile={recipient.profile} />
            </div>
            <div className={c.block3}>
                <MuiTypography className={c.label}>{txt({ en: 'Bill Details', ja: '請求の内容' })}</MuiTypography>
                <BillDataViewer issuer={issuer} recipient={recipient} billData={billData} />
            </div>
        </MuiPaper>
    );
};
BillViewerDigital.displayName = 'BillViewerDigital';
