import { FC } from 'react';

import { SharedBill } from '@/utils/UodkaClients/Invoices';
import { downloadTextFile } from '@/utils/utilFunctions';
import Button from '@/components/0_atom/Button';
import { parepareSharedBillJson } from '@/components/3_template/parepareSharedBillJson';

import SvgCode from '@/svgs/code';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        fontWeight: 600,
        background: theme.palette.primary.main,
        color: '#fff',
        minWidth: 84,
        justifyContent: 'flex-start',
        marginBottom: 7,
        '&:hover': {
            background: 'rgba(0,0,0,.55)',
        },
    },
}));

export const DownloadJsonButton: FC<{
    bill: SharedBill;
}> = ({ bill }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HANDLER
    const handleClick = () => {
        const data = parepareSharedBillJson({ sharedBill: bill });
        downloadTextFile({ fileName: 'uodka_bill.json', text: data, type: 'text/json' });
    };

    return (
        <Button className={c.root} startIcon={<SvgCode />} onClick={handleClick}>
            {'JSON'}
        </Button>
    );
};
DownloadJsonButton.displayName = 'DownloadJsonButton';
