import { FC, useState } from 'react';

import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import { BillData } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';
import { TextSelector } from '@/components/1_mol/Selector';

import { BillViewerDigital } from '@/components/2_org/BillViewer/BillViewerDigital';
import { BillDataLegacyViewer } from '@/components/2_org/BillViewer/BillDataLegacyViewer';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 740,
        position: 'relative',
    },
    selectorBox: {
        textAlign: 'right',
        position: 'absolute',
        left: -10,
        bottom: -28,
        display: 'none',
    },
    selector: {
        marginBottom: 12,
    },
}));

export const BillViewer: FC<{
    issuer: {
        name: string;
        profile: BusinessProfile | undefined;
    };
    recipient: {
        name: string;
        profile: BusinessProfile | undefined;
    };
    billData: BillData;
}> = ({ issuer, recipient, billData }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [viewMode, setViewMode] = useState<'digital' | 'paper'>('paper');

    return (
        <div className={c.root}>
            <div className={c.selectorBox}>
                <TextSelector
                    emptyPlaceholder={'unexpected'}
                    className={c.selector}
                    items={[
                        { id: 'digital', text: txt({ en: 'View: Digital', ja: '表示形式：デジタル' }) },
                        { id: 'paper', text: txt({ en: 'View: Paper', ja: '表示形式：ペーパー' }) },
                    ]}
                    onSelect={(id) => setViewMode(id as 'digital' | 'paper')}
                    selectedId={viewMode}
                />
            </div>
            {viewMode === 'digital' ? (
                <BillViewerDigital issuer={issuer} recipient={recipient} billData={billData} />
            ) : viewMode === 'paper' ? (
                <BillDataLegacyViewer billData={billData} />
            ) : (
                <></>
            )}
        </div>
    );
};
BillViewer.displayName = 'BillViewer';
