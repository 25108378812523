import { FC } from 'react';
import clsx from 'clsx';

import { BillData } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';

import { BillTo } from '@/components/2_org/BillViewer/BillDataLegacyViewer/BillTo';
import { RightTop } from '@/components/2_org/BillViewer/BillDataLegacyViewer/RightTop';
import { Items } from '@/components/2_org/BillViewer/BillDataLegacyViewer/Items';
import { Premise } from '@/components/2_org/BillViewer/BillDataLegacyViewer/Premise';
import { TableFoot } from '@/components/2_org/BillViewer/BillDataLegacyViewer/TableFoot';
import { Issuer } from '@/components/2_org/BillViewer/BillDataLegacyViewer/Issuer';
import { PaymentDestination } from '@/components/2_org/BillViewer/BillDataLegacyViewer/PaymentDestination';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiPaper from '@material-ui/core/Paper';

const billThemeColor = '#397DA1';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        // maxWidth: 700,
        // minHeight: 842,
        padding: '68px 59px',
        borderRadius: 0,
        textAlign: 'left',
    },
    title: {
        color: billThemeColor,
        fontSize: 18,
        fontWeight: 600,
        marginBottom: 28,
    },
    section1: {
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: 160,
    },
    section2: {
        marginTop: 34,
    },
    section3: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 14,
        minHeight: 80,
    },
    section4: {
        marginTop: 72,
        display: 'flex',
    },
}));

export const BillDataLegacyViewer: FC<{
    className?: string;
    billData: BillData;
}> = ({ className, billData }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    // DATA
    const decimalRoundRule = (() => {
        const { decimalRoundRuleForSubtotal } = billData.summary;
        const isContainMultipleDecimalRoundRules = !!billData.summary.taxes.find(
            ({ decimalRoundRule }) => decimalRoundRule !== decimalRoundRuleForSubtotal
        );
        if (isContainMultipleDecimalRoundRules) {
            throw new Error('BillDataLegacyViewer.decimalRoundRule: isContainMultipleDecimalRoundRules.');
        }
        return decimalRoundRuleForSubtotal;
    })();

    return (
        <MuiPaper className={clsx(c.root, className)} elevation={8}>
            <div className={c.section1}>
                <div>
                    <div className={c.title}>{txt({ en: 'INVOICE', ja: '請求書' })}</div>
                    <BillTo recipient={billData.recipient} />
                </div>
                <RightTop
                    {...{
                        total: billData.summary.total,
                        title: billData.title,
                        paymentDueDate: billData.paymentDueDate,
                        issueDate: billData.issueDate,
                        customizedId: billData.customizedId,
                        note: billData.note,
                    }}
                />
            </div>
            <div className={c.section2}>
                <Items items={billData.items} />
            </div>
            <div className={c.section3}>
                <Premise currencyCode={billData.currencyCode} decimalRoundRule={decimalRoundRule} />
                <TableFoot summary={billData.summary} />
            </div>
            <div className={c.section4}>
                <Issuer issuer={billData.issuer} />
                <PaymentDestination payee={billData.payee} recipientName={billData.recipient.name} />
            </div>
        </MuiPaper>
    );
};
BillDataLegacyViewer.displayName = 'BillDataLegacyViewer';
