import { FC } from 'react';
import clsx from 'clsx';

import { useLanguageCtx } from '@/utils/ctxs';
import Button from '@/components/0_atom/Button';

import SvgCheck from '@/svgs/fa-check-light';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        fontWeight: 600,
        // '& > button': {
        //     width: 90,
        // },
    },
    selected: {
        fontWeight: 600,
    },
    notSelected: {
        fontWeight: 600,
        color: theme.palette.text.disabled,
    },
}));

export const LanguageSelector: FC<{
    className?: string;
}> = ({ className }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { language, setLanguage } = useLanguageCtx();

    return (
        <div className={clsx(c.root, className)}>
            <Button
                onClick={() => setLanguage('en')}
                startIcon={language === 'en' || !language ? <SvgCheck /> : undefined}
                className={language === 'en' || !language ? c.selected : c.notSelected}
            >
                {'English'}
            </Button>
            <Button
                onClick={() => setLanguage('ja')}
                startIcon={language === 'ja' ? <SvgCheck /> : undefined}
                className={language === 'ja' ? c.selected : c.notSelected}
            >
                {'日本語'}
            </Button>
        </div>
    );
};
LanguageSelector.displayName = 'LanguageSelector';
