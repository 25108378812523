import { PaymentData } from '@/utils/UodkaClients/Invoices';

import { getBusinessSnapshotData } from '@/components/3_template/getModelData/getBusinessSnapshotData';
import { getPayeeData } from '@/components/3_template/getModelData/getPayeeData';
import { getInvoiceDataItemsData } from '@/components/3_template/getModelData/getInvoiceDataItemsData';
import { getInvoiceDataSummaryData } from '@/components/3_template/getModelData/getInvoiceDataSummaryData';

export const getPaymentData = ({ paymentData }: { paymentData: PaymentData }) => {
    const {
        billCustomizedId,
        currencyCode,
        customizedId,
        issueDate,
        issuer,
        items,
        note,
        payee,
        recipient,
        summary,
        title,
    } = paymentData;
    return {
        billCustomizedId,
        currencyCode,
        customizedId,
        issueDate,
        issuer: getBusinessSnapshotData({ businessSnapshot: issuer }),
        items: getInvoiceDataItemsData({ invoiceDataItems: items }),
        note,
        payee: getPayeeData({ payee }),
        recipient: getBusinessSnapshotData({ businessSnapshot: recipient }),
        summary: getInvoiceDataSummaryData({ invoiceDataSummary: summary }),
        title,
    };
};
