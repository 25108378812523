import { FC, useEffect } from 'react';

import { useLanguage } from '@/utils/customHooks';
import { getEnvVariables } from '@/utils/envVariables';
import FetchingText from '@/components/0_atom/FetchingText';

export const Redirecting: FC<{
    direction: 'issued' | 'received';
    businessId: string;
    contactId: string;
    billId: string;
}> = ({ direction, businessId, contactId, billId }) => {
    // HOOKS
    const { txt } = useLanguage();

    // USEEFFECT
    useEffect(() => {
        const consoleUrl = `${
            getEnvVariables().consoleRootUrl
        }/${businessId}/bills/${direction}/${contactId}/${billId}`;
        window.location.href = consoleUrl;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <FetchingText text={txt({ en: 'Redirecting to the console', ja: 'コンソールに移動中' })} />;
};
Redirecting.displayName = 'Redirecting';
