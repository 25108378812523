import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    value: {
        fontSize: theme.typography.body1.fontSize,
        fontWeight: 600,
    },
    rate: {
        fontWeight: 600,
        fontSize: theme.typography.body2.fontSize,
        paddingLeft: 7,
    },
}));

export const ConsumptionTaxViewer: FC<{
    rate: 0.08 | 0.1;
    taxValue: number;
}> = ({ rate, taxValue }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={c.root}>
            <span className={c.value}>{`¥ ${taxValue.toLocaleString()}`}</span>
            <span className={c.rate}>
                {'('}
                {rate === 0.1 ? '10%' : rate === 0.08 ? '8%' : 'unexpected case'}
                {')'}
            </span>
        </div>
    );
};
ConsumptionTaxViewer.displayName = 'ConsumptionTaxViewer';
