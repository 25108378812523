import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

import HealthProvider from '@/providers/HealthProvider';
import MutationsProvider from '@/providers/MutationsProvider';
import ErrorProvider from '@/providers/ErrorProvider';
import UserProvider from '@/providers/UserProvider';
import NetworkIndicator from '@/components/2_org/NetworkIndicator';
import FetchingOrErrorTemplate from '@/components/3_template/FetchingOrErrorTemplate';
import { SharedBillTemplate } from '@/components/3_template/SharedBillTemplate';
import { RedirectingTemplate } from '@/components/3_template/RedirectingTemplate';
import { useRouting } from '@/utils/customHooks';

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import muiTheme from '@/styles/muiTheme';

const Handler: FC = () => {
    // HOOKS
    const routing = useRouting();
    console.log('routing->', routing);
    // return <>aaaaa</>;
    return routing?.inviteCodeText ? (
        <SharedBillTemplate
            issuerBusinessId={routing.issuerBusinessId}
            issuerContactId={routing.issuerContactId}
            billId={routing.billId}
            inviteCodeText={routing.inviteCodeText}
        />
    ) : routing?.recipientBusinessId && routing.recipientContactId ? (
        <RedirectingTemplate
            issuerBusinessId={routing.issuerBusinessId}
            issuerContactId={routing.issuerContactId}
            billId={routing.billId}
            recipientBusinessId={routing.recipientBusinessId}
            recipientContactId={routing.recipientContactId}
        />
    ) : (
        <FetchingOrErrorTemplate />
    );
};

const AppRoot: FC = () => {
    return (
        <ErrorProvider>
            <HealthProvider>
                <MutationsProvider>
                    <UserProvider>
                        <BrowserRouter>
                            <ThemeProvider theme={muiTheme}>
                                <CssBaseline />
                                <NetworkIndicator />
                                <Handler />
                            </ThemeProvider>
                        </BrowserRouter>
                    </UserProvider>
                </MutationsProvider>
            </HealthProvider>
        </ErrorProvider>
    );
};
AppRoot.displayName = 'AppRoot';
export default AppRoot;
