import { BillSignatures } from '@/utils/UodkaClients/Invoices';

import { getSignatureData } from '@/components/3_template/getModelData/getSignatureData';

export const getBillSignaturesData = ({ billSignatures }: { billSignatures: BillSignatures }) => {
    const { issuer, recipient } = billSignatures;
    return {
        issuer: (() => {
            const { issue, revoke } = issuer;
            return {
                issue: (() => {
                    const { issuedAt, signature } = issue;
                    return {
                        issuedAt,
                        signature: getSignatureData({ signature }),
                    };
                })(),
                revoke: (() => {
                    if (revoke) {
                        const { agreeToRevokeAt, signature } = revoke;
                        return {
                            agreeToRevokeAt,
                            signature: getSignatureData({ signature }),
                        };
                    }
                    return undefined;
                })(),
            };
        })(),
        recipient: (() => {
            const { receive, revoke } = recipient;
            return {
                receive: (() => {
                    if (receive) {
                        const { billReceivedAt, signature } = receive;
                        return {
                            billReceivedAt,
                            signature: getSignatureData({ signature }),
                        };
                    }
                    return undefined;
                })(),
                revoke: (() => {
                    if (revoke) {
                        const { agreeToRevokeAt, signature } = revoke;
                        return {
                            agreeToRevokeAt,
                            signature: getSignatureData({ signature }),
                        };
                    }
                    return undefined;
                })(),
            };
        })(),
    };
};
