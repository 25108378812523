import { FC } from 'react';

import { SharedBill } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';
import { downloadTextFile } from '@/utils/utilFunctions';
import Button from '@/components/0_atom/Button';
import { parepareBillCsv } from '@/components/3_template/parepareBillCsv';

import SvgSpreadsheet from '@/svgs/spreadsheet';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        fontWeight: 600,
        background: theme.palette.primary.main,
        color: '#fff',
        minWidth: 84,
        justifyContent: 'flex-start',
        marginBottom: 7,
        '&:hover': {
            background: 'rgba(0,0,0,.55)',
        },
    },
}));

export const DownloadCSVButton: FC<{
    bill: SharedBill;
}> = ({ bill }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    // HANDLER
    const handleClick = () => {
        const data = parepareBillCsv({ billData: bill.billData, txt });
        downloadTextFile({ fileName: 'uodka_bill.csv', text: data, type: 'text/csv' });
    };

    return (
        <Button className={c.root} startIcon={<SvgSpreadsheet />} onClick={handleClick}>
            {'CSV'}
        </Button>
    );
};
DownloadCSVButton.displayName = 'DownloadCSVButton';
