import { FC, ReactNode, useState, useEffect } from 'react';

import { InviteForContact } from '@/utils/UodkaClients/Core';
import { LanguageCtx, useUserCtx } from '@/utils/ctxs';

const LanguageProvider: FC<{
    invite: InviteForContact | undefined;
    children: ReactNode;
}> = ({ invite, children }) => {
    // HOOKS
    const { userClient } = useUserCtx();
    const [language, setLanguage] = useState<'en' | 'ja' | undefined>(
        userClient?.language || invite?.business.language
    );

    // USEEFFECT
    useEffect(() => {
        const newLanguage = userClient?.language || invite?.business.language;
        if (newLanguage !== language) {
            setLanguage(newLanguage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userClient?.language, invite?.business.language]);

    return <LanguageCtx.Provider value={{ language, setLanguage }}>{children}</LanguageCtx.Provider>;
};
LanguageProvider.displayName = 'LanguageProvider';
export default LanguageProvider;
