import { FC } from 'react';

import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import { BillData } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';
import { DepositAccountCardWithHolder, ExternalBankAccountCardWithHolder } from '@/components/2_org/BankAccountCard';
import { getStrWithBr } from '@/utils/utilFunctions';

import { BillDataViewerItem } from '@/components/2_org/BillViewer/BillViewerDigital/BillDataViewer/BillDataViewerItem';
import { BusinessSnapshotViewer } from '@/components/2_org/BillViewer/BillViewerDigital/BillDataViewer/BusinessSnapshotViewer';
import { ItemsViewer } from '@/components/2_org/BillViewer/BillViewerDigital/BillDataViewer/ItemsViewer';
import { TableFoot } from '@/components/2_org/BillViewer/BillViewerDigital/BillDataViewer/TableFoot';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '28px 19px',
        borderRadius: 4,
        border: 'solid 2px rgba(0,0,0,.04)',
    },
    bankAccountCard: {
        padding: 14,
        borderRadius: 4,
        border: 'solid 2px rgba(0,0,0,.04)',
        display: 'inline-block',
    },
    text: {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 600,
        background: '#fafafa',
        padding: 14,
        borderRadius: 4,
        display: 'inline-block',
    },
    longText: {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 600,
        background: '#fafafa',
        padding: 14,
        borderRadius: 4,
        display: 'inline-block',
        minWidth: 300,
    },
    tableFootBox: {
        marginTop: 7,
    },
}));

export const BillDataViewer: FC<{
    issuer: {
        name: string;
        profile: BusinessProfile | undefined;
    };
    recipient: {
        name: string;
        profile: BusinessProfile | undefined;
    };
    billData: BillData;
}> = ({ issuer, recipient, billData }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt, getDateStr } = useLanguage();

    // DATA
    const decimalRoundRule = (() => {
        const { decimalRoundRuleForSubtotal } = billData.summary;
        const differentRule = billData.summary.taxes.find(
            (tax) => tax.decimalRoundRule !== decimalRoundRuleForSubtotal
        );
        if (differentRule) {
            throw new Error('BillDataViewer.decimalRoundRule: Multiple decimalRoundRules in a bill');
        }
        return decimalRoundRuleForSubtotal;
    })();

    return (
        <div className={c.root}>
            <BillDataViewerItem label={txt({ en: 'Bill to', ja: '請求先' })}>
                <BusinessSnapshotViewer businessSnapshot={billData.recipient} />
            </BillDataViewerItem>
            <BillDataViewerItem label={txt({ en: 'Issuer', ja: '発行者' })}>
                <BusinessSnapshotViewer businessSnapshot={billData.issuer} />
            </BillDataViewerItem>
            <BillDataViewerItem label={txt({ en: 'Payment to', ja: '支払い先' })}>
                {billData.payee.uodkaBankAccount.isDepositAccount ? (
                    <DepositAccountCardWithHolder
                        className={c.bankAccountCard}
                        depositAccount={billData.payee.uodkaBankAccount}
                        holder={issuer}
                        remitter={recipient}
                    />
                ) : (
                    <ExternalBankAccountCardWithHolder
                        className={c.bankAccountCard}
                        bankAccount={billData.payee.uodkaBankAccount}
                        holder={issuer}
                    />
                )}
            </BillDataViewerItem>
            <BillDataViewerItem label={txt({ en: 'Items', ja: '項目' })}>
                <>
                    <ItemsViewer items={billData.items} />
                    <div className={c.tableFootBox}>
                        <TableFoot summary={billData.summary} />
                    </div>
                </>
            </BillDataViewerItem>
            <BillDataViewerItem label={txt({ en: 'Currency', ja: '通貨' })}>
                <div className={c.text}>
                    {billData.currencyCode === 'jpy' ? txt({ en: 'JPY', ja: '日本円' }) : billData.currencyCode}
                </div>
            </BillDataViewerItem>
            <BillDataViewerItem label={txt({ en: 'Dealing with Decimals', ja: '小数点以下の処理' })}>
                <div className={c.text}>
                    {decimalRoundRule === 'down'
                        ? txt({ en: 'Round down', ja: '切り捨て' })
                        : decimalRoundRule === 'up'
                        ? txt({ en: 'Round up', ja: '切り上げ' })
                        : decimalRoundRule === 'near'
                        ? txt({ en: 'Round', ja: '四捨五入' })
                        : decimalRoundRule}
                </div>
            </BillDataViewerItem>
            <BillDataViewerItem label={txt({ en: 'Subject', ja: '件名' })}>
                <div className={c.longText}>{billData.title || '-'}</div>
            </BillDataViewerItem>
            <BillDataViewerItem label={txt({ en: 'Invoice #', ja: '請求書コード' })}>
                <div className={c.longText}>{billData.customizedId || '-'}</div>
            </BillDataViewerItem>
            <BillDataViewerItem label={txt({ en: 'Date of issue', ja: '発行日' })}>
                <div className={c.text}>{billData.issueDate ? getDateStr(billData.issueDate) : '-'}</div>
            </BillDataViewerItem>
            <BillDataViewerItem label={txt({ en: 'Payment due date', ja: '支払い期限' })}>
                <div className={c.text}>{billData.paymentDueDate ? getDateStr(billData.paymentDueDate) : '-'}</div>
            </BillDataViewerItem>
            <BillDataViewerItem label={txt({ en: 'Note', ja: 'メッセージ' })}>
                <div className={c.longText}>{billData.note ? getStrWithBr(billData.note) : '-'}</div>
            </BillDataViewerItem>
        </div>
    );
};
BillDataViewer.displayName = 'BillDataViewer';
