import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    img: {
        width: 'auto',
        height: '100%',
        objectFit: 'contain',
        verticalAlign: 'top',
    },
    name: {
        fontWeight: 600,
        color: theme.palette.primary.main,
        // color: theme.palette.text.disabled,
        fontSize: theme.typography.body1.fontSize,
    },
}));

export const BusinessLogo: FC<{
    className?: string;
    name: string;
    logoSrc?: string;
}> = ({ className, name, logoSrc }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={className}>
            {logoSrc ? <img className={c.img} src={logoSrc} alt={name} /> : <span className={c.name}>{name}</span>}
        </div>
    );
};
BusinessLogo.displayName = 'BusinessLogo';
