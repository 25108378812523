import { SharedBill } from '@/utils/UodkaClients/Invoices';

import { getBillData } from '@/components/3_template/getModelData/getBillData';
import { getPaymentData } from '@/components/3_template/getModelData/getPaymentData';
import { getReceiptData } from '@/components/3_template/getModelData/getReceiptData';
import { getBillSignaturesData } from '@/components/3_template/getModelData/getBillSignaturesData';

export const getSharedBillData = ({ sharedBill }: { sharedBill: SharedBill }) => {
    const { billData, createdAt, id, payment, receipt, signatures } = sharedBill;
    return {
        billData: getBillData({ billData }),
        createdAt,
        id,
        payment: (() => {
            if (payment) {
                const { createdAt, id, paymentData } = payment;
                return {
                    createdAt,
                    id,
                    paymentData: getPaymentData({ paymentData }),
                };
            }
            return undefined;
        })(),
        receipt: (() => {
            if (receipt) {
                const { createdAt, id, receiptData } = receipt;
                return {
                    createdAt,
                    id,
                    receiptData: getReceiptData({ receiptData }),
                };
            }
            return undefined;
        })(),
        signatures: getBillSignaturesData({ billSignatures: signatures }),
    };
};
