import { FC } from 'react';

import { InviteForContact } from '@/utils/UodkaClients/Core';
import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import { SharedBill } from '@/utils/UodkaClients/Invoices';
import { useLanguage } from '@/utils/customHooks';
import { BusinessLogo } from '@/components/2_org/BusinessLogo';
import { BillViewer } from '@/components/2_org/BillViewer';
import { BillDataLegacyViewer } from '@/components/2_org/BillViewer/BillDataLegacyViewer';
import { LanguageSelector } from '@/components/2_org/LanguageSelector';

import { DownloadCSVButton } from '@/components/3_template/SharedBillTemplate/DownloadCSVButton';
import { DownloadJsonButton } from '@/components/3_template/SharedBillTemplate/DownloadJsonButton';
import { DownloadPDFButton } from '@/components/3_template/SharedBillTemplate/DownloadPDFButton';
import { ImportButton } from '@/components/3_template/SharedBillTemplate/ImportButton';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '30px 34px',
        position: 'relative',
    },
    topBrand: {
        width: 'auto',
        height: 24,
        position: 'fixed',
        top: 20,
        left: 34,
        // zIndex: 10,
    },
    content: {
        margin: '80px auto 0px auto',
    },
    bill: {},
    right: {
        position: 'fixed',
        top: 200,
        left: 34 + 740 + 34,
    },
    h1: {
        fontWeight: 600,
        fontSize: 24,
        color: theme.palette.primary.main,
    },
    h1Foot: {
        marginTop: 11,
        fontSize: 18,
        fontWeight: 600,
    },
    languageSelector: {
        position: 'fixed',
        bottom: 30,
        right: 34,
    },
    downloads: {
        marginTop: 46,
    },
    label: {
        fontWeight: 600,
        fontSize: theme.typography.caption.fontSize,
        // color: theme.palette.text.disabled,
        marginBottom: 7,
    },
    buttons: {
        '& > button:not(:last-child)': {
            marginRight: 7,
        },
    },
    imports: {
        marginTop: 33,
    },
}));

export const SharedBillTemplateContent: FC<{
    bill: SharedBill;
    invite: InviteForContact;
    issuerProfile: BusinessProfile | undefined;
}> = ({ bill, invite, issuerProfile }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { language, txt } = useLanguage();

    return (
        <div className={c.root}>
            <BusinessLogo className={c.topBrand} name={bill.billData.issuer.name} logoSrc={issuerProfile?.logoSrc} />
            <div className={c.content}>
                <div className={c.bill}>
                    <BillViewer
                        issuer={{
                            name: bill.billData.issuer.name,
                            profile: issuerProfile,
                        }}
                        recipient={{
                            name: bill.billData.recipient.name,
                            profile: undefined,
                        }}
                        billData={bill.billData}
                    />
                </div>
                <div className={c.right}>
                    <MuiTypography variant={'h1'} className={c.h1}>
                        {txt({
                            en: `Your invoice from ${bill.billData.issuer.name}`,
                            ja: `${bill.billData.recipient.name}様`,
                        })}
                    </MuiTypography>
                    <MuiTypography className={c.h1Foot}>
                        {txt({
                            en: '',
                            ja: '請求書をお送りいたします。',
                        })}
                    </MuiTypography>
                    <div className={c.downloads}>
                        <MuiTypography className={c.label}>
                            {txt({
                                en: 'Download',
                                ja: 'ダウンロード',
                            })}
                        </MuiTypography>
                        <div className={c.buttons}>
                            <DownloadCSVButton bill={bill} />
                            <DownloadJsonButton bill={bill} />
                            <DownloadPDFButton bill={bill} />
                        </div>
                    </div>
                    <div className={c.imports}>
                        <MuiTypography className={c.label}>
                            {txt({
                                en: 'Import',
                                ja: 'インポート',
                            })}
                        </MuiTypography>
                        <div className={c.buttons}>
                            <ImportButton bill={bill} invite={invite} />
                        </div>
                    </div>
                </div>
            </div>
            <LanguageSelector className={c.languageSelector} />
        </div>
    );
};
SharedBillTemplateContent.displayName = 'SharedBillTemplateContent';
