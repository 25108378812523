import { FC, useState, useEffect } from 'react';

import { MemberInUser } from '@/utils/UodkaClients/Core';
import { useUserCtx, useErrorCtx } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { getEnvVariables } from '@/utils/envVariables';
import LanguageProvider from '@/providers/LanguageProvider';
import FetchingText from '@/components/0_atom/FetchingText';
import ErrorDisplay from '@/components/2_org/ErrorDisplay';
import SigninBox from '@/components/2_org/SigninBox';

import SvgUodkaLogo from '@/svgs/uodkalogo';

import { useRelatedMembers } from '@/components/3_template/RedirectingTemplate/useRelatedMembers';
import { SelectContent } from '@/components/3_template/RedirectingTemplate/SelectContent';
import { Redirecting } from '@/components/3_template/RedirectingTemplate/Redirecting';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateY(-50%) translateX(-50%)',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        color: theme.palette.primary.main,
        height: 20,
    },
    content: {
        marginTop: 24,
    },
}));

export const RedirectingTemplate: FC<{
    issuerBusinessId: string;
    issuerContactId: string;
    billId: string;
    recipientBusinessId: string;
    recipientContactId: string;
}> = ({ issuerBusinessId, issuerContactId, billId, recipientBusinessId, recipientContactId }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { userClient, firebaseUser, issuerMember, recipientMember, isFetching } = useRelatedMembers({
        issuerBusinessId,
        recipientBusinessId,
    });

    // DATA
    console.log('{userClient, firebaseUser,issuerMember,recipientMember}->', {
        userClient,
        firebaseUser,
        issuerMember,
        recipientMember,
        issuerBusinessId,
        issuerContactId,
        billId,
        recipientBusinessId,
        recipientContactId,
        isFetching,
    });
    return (
        <LanguageProvider invite={undefined}>
            <div className={c.root}>
                <SvgUodkaLogo className={c.logo} />
                <div className={c.content}>
                    {isFetching ? (
                        <FetchingText />
                    ) : userClient ? (
                        issuerMember && recipientMember ? (
                            <SelectContent
                                issuerMember={issuerMember}
                                recipientMember={recipientMember}
                                issuerContactId={issuerContactId}
                                recipientContactId={recipientContactId}
                                billId={billId}
                            />
                        ) : issuerMember ? (
                            <Redirecting
                                direction={'issued'}
                                businessId={issuerBusinessId}
                                contactId={issuerContactId}
                                billId={billId}
                            />
                        ) : recipientMember ? (
                            <Redirecting
                                direction={'received'}
                                businessId={recipientBusinessId}
                                contactId={recipientContactId}
                                billId={billId}
                            />
                        ) : (
                            <ErrorDisplay errorCode={404} />
                        )
                    ) : firebaseUser ? (
                        // no account
                        <ErrorDisplay errorCode={404} />
                    ) : (
                        <SigninBox />
                    )}
                </div>
            </div>
        </LanguageProvider>
    );
};
RedirectingTemplate.displayName = 'RedirectingTemplate';
