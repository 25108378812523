import { FC } from 'react';

import { InvoiceDataItem } from '@/utils/UodkaClients/Invoices';

import { ItemViewer } from '@/components/2_org/BillViewer/BillViewerDigital/BillDataViewer/ItemsViewer/ItemViewer';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        borderRadius: 4,
        padding: '14px 7px',
        background: '#f6f6f6',
    },
}));

export const ItemsViewer: FC<{
    items: InvoiceDataItem[];
}> = ({ items }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={c.root}>
            {items.map((item, i) => (
                <ItemViewer key={i} item={item} />
            ))}
        </div>
    );
};
ItemsViewer.displayName = 'ItemsViewer';
