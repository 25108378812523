import { BillData, DecimalRoundRule } from '@/utils/UodkaClients/Invoices';
import { getCSV } from '@/utils/utilFunctions';

import { getTaxName } from '@/components/3_template/getTaxName';

const getDecimalRoundRuleStr = ({
    decimalRoundRule,
    txt,
}: {
    decimalRoundRule: DecimalRoundRule;
    txt: (input: { en: string; ja?: string }) => string;
}) => {
    return decimalRoundRule === 'down'
        ? txt({ en: 'Round down', ja: '切り捨て' })
        : decimalRoundRule === 'up'
        ? txt({ en: 'Round up', ja: '切り上げ' })
        : decimalRoundRule === 'near'
        ? txt({ en: 'Round', ja: '四捨五入' })
        : 'unexpected case';
};

export const parepareBillCsv = ({
    billData,
    txt,
}: {
    billData: BillData;
    txt: (input: { en: string; ja?: string }) => string;
}) => {
    const headRowOrigin = [
        txt({ en: 'Description', ja: '項目' }),
        txt({ en: 'Unit Price', ja: '単価' }),
        txt({ en: 'Quantity', ja: '数量' }),
        txt({ en: 'Subtotal', ja: '小計' }),
    ];
    const headRowTaxes = billData.summary.taxes.reduce(
        (acc, { title }) => [...acc, getTaxName({ taxTitle: title, txt })],
        [] as string[]
    );
    const bodyRows = billData.items.map((item) => {
        const bodyRowOrigin = [item.title, item.unitPrice, item.quantity, item.unitPrice * item.quantity];
        const bodyRowTaxes = headRowTaxes.map((taxTitle) => {
            const tax = item.taxes.find(({ title }) => getTaxName({ taxTitle: title, txt }) === taxTitle);
            return tax?.value;
        });
        return [...bodyRowOrigin, ...bodyRowTaxes];
    });
    const summaryRows = (() => {
        const taxes = headRowTaxes.map((taxTitle) => {
            const tax = billData.summary.taxes.find(({ title }) => getTaxName({ taxTitle: title, txt }) === taxTitle);
            return tax?.value;
        });
        const subtotalRow = [
            `${txt({ en: 'Subtotal', ja: '小計' })} (${getDecimalRoundRuleStr({
                decimalRoundRule: billData.summary.decimalRoundRuleForSubtotal,
                txt,
            })})`,
            undefined,
            undefined,
            billData.summary.subtotal,
            ...taxes,
        ];
        const taxRowsHead = [
            txt({ en: 'Taxe Name', ja: '税金の名称' }),
            txt({ en: 'Taxable Amount', ja: '対象' }),
            txt({ en: 'Tax Value', ja: '税額' }),
            txt({ en: 'Dealing with decimals', ja: '小数点以下の処理' }),
        ];
        const taxRows = billData.summary.taxes.map((tax) => {
            return [
                getTaxName({ taxTitle: tax.title, txt }),
                tax.taxableValue,
                tax.value,
                getDecimalRoundRuleStr({ decimalRoundRule: tax.decimalRoundRule, txt }),
            ];
        });
        const totalRow = [txt({ en: 'Total', ja: '合計' }), undefined, undefined, billData.summary.total];
        return [[], subtotalRow, totalRow, [], taxRowsHead, ...taxRows];
    })();

    const csv = getCSV([[...headRowOrigin, ...headRowTaxes], ...bodyRows, ...summaryRows]);
    return csv;
};
