import { FC } from 'react';

import { InviteForContact } from '@/utils/UodkaClients/Core';
import { SharedBill } from '@/utils/UodkaClients/Invoices';
import Button from '@/components/0_atom/Button';
import { useLanguage } from '@/utils/customHooks';
import { getEnvVariables } from '@/utils/envVariables';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        fontWeight: 600,
        background: theme.palette.primary.main,
        color: '#fff',
        minWidth: 84,
        height: 33,
        marginBottom: 7,
        '&:hover': {
            background: 'rgba(0,0,0,.55)',
        },
    },
    svg: {
        height: 10,
    },
}));

export const ImportButton: FC<{
    bill: SharedBill;
    invite: InviteForContact;
}> = ({ bill, invite }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    // DATA
    const inviteUrl = `${getEnvVariables().inviteRootUrl}/contact/${invite.business.id}/${invite.contact.id}/${
        invite.codeText
    }`;

    return (
        <Button className={c.root} href={inviteUrl}>
            {txt({ en: 'Import to Uodka', ja: 'Uodkaにインポート' })}
        </Button>
    );
};
ImportButton.displayName = 'ImportButton';
