import { FC, useState, useEffect } from 'react';

import { MemberInUser } from '@/utils/UodkaClients/Core';
import { useUserCtx, useErrorCtx } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import { getEnvVariables } from '@/utils/envVariables';
import FirebaseAuth from '@/components/0_atom/FirebaseAuth';
import Button from '@/components/0_atom/Button';
import SigninBox from '@/components/2_org/SigninBox';

import SvgUodkaLogo from '@/svgs/uodkalogo';

import { Redirecting } from '@/components/3_template/RedirectingTemplate/Redirecting';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiPaper from '@material-ui/core/Paper';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 7,
        padding: '26px 27px',
        position: 'relative',
        minWidth: 320,
    },
    desciprion: {
        marginTop: 36,
    },
    buttons: {
        marginTop: 28,
    },
    button: {
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '8px 12px',
        fontSize: theme.typography.body2.fontSize,
        '&:not(:last-child)': {
            marginBottom: 7,
        },
    },
}));

export const SelectContent: FC<{
    issuerMember: MemberInUser;
    recipientMember: MemberInUser;
    issuerContactId: string;
    billId: string;
    recipientContactId: string;
}> = ({ issuerMember, recipientMember, issuerContactId, billId, recipientContactId }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { setIs404 } = useErrorCtx();
    const { txt } = useLanguage();
    const [selectedMember, setSelectedMember] = useState<
        { member: MemberInUser; direction: 'issued' | 'received'; contactId: string } | undefined
    >(undefined);

    return (
        <MuiPaper className={c.root}>
            {selectedMember ? (
                <Redirecting
                    direction={selectedMember.direction}
                    businessId={selectedMember.member.business.id}
                    contactId={selectedMember.contactId}
                    billId={billId}
                />
            ) : (
                <>
                    <MuiTypography variant={'h1'}>
                        {txt({ en: 'Redirecting to the console', ja: 'コンソールへリダイレクト' })}
                    </MuiTypography>
                    <MuiTypography className={c.desciprion}>
                        {txt({ en: 'Open console as', ja: 'どちらのビジネスで開きますか？' })}
                    </MuiTypography>
                    <div className={c.buttons}>
                        <Button
                            className={c.button}
                            onClick={() =>
                                setSelectedMember({
                                    member: issuerMember,
                                    contactId: issuerContactId,
                                    direction: 'issued',
                                })
                            }
                            role={'submit'}
                        >
                            {issuerMember.business.name}
                        </Button>
                        <Button
                            className={c.button}
                            onClick={() =>
                                setSelectedMember({
                                    member: recipientMember,
                                    contactId: recipientContactId,
                                    direction: 'received',
                                })
                            }
                            role={'submit'}
                        >
                            {recipientMember.business.name}
                        </Button>
                    </div>
                </>
            )}
        </MuiPaper>
    );
};
SelectContent.displayName = 'SelectContent';
