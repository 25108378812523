import { useState, useEffect, useCallback } from 'react';

import { MemberInUser } from '@/utils/UodkaClients/Core';
import { useUserCtx } from '@/utils/ctxs';

export const useRelatedMembers = ({
    issuerBusinessId,
    recipientBusinessId,
}: {
    issuerBusinessId: string;
    recipientBusinessId: string;
}) => {
    // HOOKS
    const { userClient, firebaseUser, isFetching: isFetchingUser } = useUserCtx();
    const [isFetching, setIsFetching] = useState(true);
    const [issuerMember, setIssuerMember] = useState<MemberInUser | undefined>();
    const [recipientMember, setRecipientMember] = useState<MemberInUser | undefined>();

    // CALLBACK
    const fetchMembers = useCallback(async () => {
        if (userClient) {
            const members = await userClient.getMembers();
            setIssuerMember(members.find(({ business }) => business.id === issuerBusinessId));
            setRecipientMember(members.find(({ business }) => business.id === recipientBusinessId));
            setIsFetching(false);
        } else if (!isFetchingUser) {
            setIsFetching(false);
        }
    }, [issuerBusinessId, recipientBusinessId, userClient, isFetchingUser]);

    // USEEFFECT
    useEffect(() => {
        fetchMembers();
    }, [fetchMembers]);

    return {
        firebaseUser,
        userClient,
        issuerMember,
        recipientMember,
        isFetching: isFetchingUser || isFetching,
    };
};
