import { useState, useEffect, useCallback } from 'react';

import { InviteForContact } from '@/utils/UodkaClients/Core';
import { initUodkaInvoicesPublicClient, SharedBill } from '@/utils/UodkaClients/Invoices';

export const useSharedBill = ({
    issuerBusinessId,
    issuerContactId,
    billId,
    inviteCodeText,
}: {
    issuerBusinessId: string;
    issuerContactId: string;
    billId: string;
    inviteCodeText: string;
}) => {
    // HOOKS
    const [fetchedBill, setFetchiedBill] = useState<
        | {
              bill: SharedBill;
              invite: InviteForContact;
          }
        | undefined
        | 'fetching'
    >('fetching');
    const fetchBill = useCallback(async () => {
        const client = initUodkaInvoicesPublicClient();
        const response = await client
            .getSharedBill({
                issuerBusinessId,
                issuerContactId,
                billId,
                inviteCodeText,
            })
            .catch(() => undefined);
        setFetchiedBill(response);
    }, [billId, inviteCodeText, issuerBusinessId, issuerContactId]);

    // USEEFFECT
    useEffect(() => {
        fetchBill();
    }, [fetchBill]);

    return {
        sharedBill: fetchedBill,
    };
};
