import { FC, useEffect } from 'react';

import { useErrorCtx } from '@/utils/ctxs';
import LanguageProvider from '@/providers/LanguageProvider';
import FetchingText from '@/components/0_atom/FetchingText';
import { useSharedBill, useBusinessProfile } from '@/utils/customHooks';

import { SharedBillTemplateContent } from '@/components/3_template/SharedBillTemplate/SharedBillTemplateContent';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    fetchingText: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateY(-50%) translateX(-50%)',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

export const SharedBillTemplate: FC<{
    issuerBusinessId: string;
    issuerContactId: string;
    billId: string;
    inviteCodeText: string;
}> = ({ issuerBusinessId, issuerContactId, billId, inviteCodeText }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { setIs404 } = useErrorCtx();
    const { sharedBill } = useSharedBill({ issuerBusinessId, issuerContactId, billId, inviteCodeText });
    const { profile } = useBusinessProfile({ businessId: issuerBusinessId });

    // USEEFFECT
    useEffect(() => {
        if (!sharedBill) {
            setIs404(true);
        }
    }, [setIs404, sharedBill]);

    return (
        <>
            {sharedBill === 'fetching' || profile === 'fetching' ? (
                <FetchingText className={c.fetchingText} />
            ) : !sharedBill ? (
                <></>
            ) : (
                <LanguageProvider invite={sharedBill.invite}>
                    <SharedBillTemplateContent
                        bill={sharedBill.bill}
                        invite={sharedBill.invite}
                        issuerProfile={profile}
                    />
                </LanguageProvider>
            )}
        </>
    );
};
SharedBillTemplate.displayName = 'SharedBillTemplate';
